import React, { useEffect } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';
import { useLocation } from 'react-router-dom';

const TRACKING_API_URL = 'https://ipapi.co/json/';

const NotifyIfGermanVisitor = () => {
    const location = useLocation();
    const accessedEndpoint = location.pathname;

  useEffect(() => {
    const checkVisitor = async () => {
      try {
        const response = await axios.get(TRACKING_API_URL);
        const countryCode = response.data.country_code;
        // Inside your component or hook

        // Check if the visitor is from Germany (DE)
        if (countryCode === 'IT') {
          // Send an email if the visitor is from Germany
          emailjs.send('service_f6rysz3', 'template_50w89xm', {
            to_name: 'Your Name',
            from_name: 'Visitor Notification',
            message: `A visitor from Germany has accessed your application at the endpoint: ${accessedEndpoint}.`,
          }, '6bpuv-HekVMQDzc16')
          .then((result) => {
            console.log('Email sent successfully:', result.text);
          })
          .catch((error) => {
            console.error('Error sending email:', error);
          });
        }
      } catch (error) {
        console.error('Error checking visitor location:', error);
      }
    };

    checkVisitor();
  }, []);

  return null;
};

export default NotifyIfGermanVisitor;