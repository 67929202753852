import React from 'react';
import { Container, Header, Content } from 'rsuite';
import PostList from '../components/PostList';

const HomePage = () => {
    const homeBackgroundStyle = {
        backgroundImage: 'url(/jesus.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        color: '#1a237e', // To ensure text is readable over the background
    };

    return (
        <Container style={homeBackgroundStyle}>
            <Header>
                <h2 style={{ textAlign: 'center', marginTop: 0, paddingTop: 20}}>Condividendo la luce di Cristo attraverso parole e fede.</h2>
            </Header>
            <Content>
                <PostList />
            </Content>
        </Container>
    );
};

export default HomePage;