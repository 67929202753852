import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Header, Content, Panel, Divider, FlexboxGrid, IconButton } from 'rsuite';
import { ArrowLeftOutlined } from '@ant-design/icons';


const posts = [
    {
        id: 1,
        title: "5 Cose da Fare e 5 Cose da Evitare Quando si Viaggia a Luglio e Agosto",
        photo:'/bus1.jpg',
        description: "Viaggiare durante i mesi estivi di punta, luglio e agosto, può essere un'esperienza entusiasmante, ma le superstizioni e le credenze culturali spesso giocano un ruolo nella pianificazione dei viaggi. Questa guida offre consigli pratici basati su superstizioni comuni per migliorare la tua esperienza di viaggio durante questi mesi affollati.",
        sections: [
            {
                subHeader: "Consigli per un Viaggio Fluido e Piacevole",
                content: "Viaggiare può essere un'avventura emozionante, specialmente durante i mesi estivi più intensi. Per rendere il tuo viaggio ancora migliore e evitare possibili problemi, è utile conoscere alcune pratiche e consigli comuni. In questo post, condividiamo consigli utili per migliorare la tua esperienza di viaggio, dai portare amuleti di buona fortuna alla scelta dei giorni migliori per partire. Segui queste semplici linee guida per assicurarti un viaggio più fluido e piacevole.",
            },
            {
                subHeader: "5 Cose da Fare",
                content: "**1. Porta un amuleto di buon auspicio:** Le superstizioni spesso suggeriscono che portare un amuleto di buon auspicio, come un ciondolo fortunato, una pietra speciale o un talismano personale, può proteggerti e portare energia positiva. Molte culture credono che questi oggetti abbiano poteri protettivi e possano proteggerti da potenziali contrattempi. Portare un amuleto è pensato per aumentare la fiducia e offrire un senso di sicurezza durante il viaggio.",
                image: '/post1photo3.jpg',
            },
            {
                subHeader: "",
                content: "**2. Esegui una Purificazione Rituale:** Molte culture credono nell'eseguire una purificazione o un rituale di pulizia prima di intraprendere un viaggio. Questo può includere varie pratiche come fare un bagno simbolico, bruciare incenso, o semplicemente meditare per liberarsi delle energie negative. Tali rituali sono considerati utili per prepararsi spiritualmente e mentalmente, contribuendo a garantire un'esperienza di viaggio fluida e favorevole.",
                image:''
            },
            {
                subHeader: "",
                content: "**3. Fai una preghiera o una benedizione:** Prendere un momento per una preghiera o una benedizione prima di partire è ritenuto invocare protezione e guida divina. Questa tradizione è diffusa in molte culture come modo per cercare favore e garantire un viaggio sicuro. Si pensa che invocare una potenza superiore o offrire una benedizione possa proteggere i viaggiatori dai pericoli e garantire un viaggio fluido.",
                image:''
            },
            {
                subHeader: "",
                content: "**4. Parti con un segno positivo:** Partire con un gesto positivo, come un saluto allegro o un sorriso, è ritenuto impostare un tono favorevole per il tuo viaggio. Le superstizioni suggeriscono che una partenza positiva possa influenzare l'esito del viaggio, attirando buona fortuna e creando un'esperienza di viaggio piacevole. È un modo per prepararsi mentalmente a un viaggio positivo.",
                image:'/post1photo4.jpg'
            },
            {
                subHeader: "",
                content: "**5. Porta una foglia di rosmarino in tasca:** Il rosmarino è da tempo associato alla protezione e alla buona fortuna in varie culture. Portare con te una foglia di rosmarino si crede possa proteggerti dalle energie negative e portare vibrazioni positive. Il rosmarino è pensato per avere proprietà spirituali e protettive, rendendolo una scelta popolare per coloro che seguono superstizioni legate ai viaggi.",
                image:''
            },
            {
                subHeader: "5 Cose da Evitare",
                content: "**1. Evita di viaggiare il 4, il 13 o il Venerdì:** In diverse culture, certe date e giorni sono considerati particolarmente sfortunati a causa delle loro associazioni con la sventura e le superstizioni storiche. Ecco un'analisi più dettagliata:\n   - **Il 4:** Il numero 4 è spesso evitato a causa della sua somiglianza fonetica con la parola 'morte'. Questa associazione con la morte rende il 4 del mese un giorno da trattare con cautela quando si iniziano nuove imprese, incluso il viaggio. È consigliabile evitare di essere in viaggio il 4, poiché si ritiene che questo giorno possa portare sfortuna e problemi imprevisti.\n   - **Il 13:** Il numero 13 è famosamente considerato sfortunato in molte culture occidentali, derivante da antiche superstizioni e credenze religiose. La paura del 13, nota come triskaidekafobia, è così prevalente che alcuni edifici saltano addirittura il 13° piano. Nel contesto dei viaggi, evitare il 13 del mese è pensato per prevenire potenziali sfortune o disavventure.\n   - **Il Venerdì:** In varie tradizioni, il Venerdì è visto come un giorno infausto per iniziare nuove imprese. Questa credenza può essere fatta risalire a contesti religiosi e storici, come la convinzione che Gesù sia stato crocifisso di Venerdì. Per alcuni, questa associazione con un giorno di sventura storica porta ad evitare di iniziare attività importanti, compreso il viaggio, di Venerdì.\n   Per ridurre l'impatto di queste superstizioni, considera di pianificare il tuo itinerario evitando queste date e giorni. Questo approccio può aiutare a ridurre l'ansia associata all'inizio del viaggio o al trovarsi in viaggio durante questi tempi potenzialmente sfortunati.",
                image: '/post1photo2.jpg',
            },
            {
                subHeader: "",
                content: "**2. Non Iniziare il Tuo Viaggio Quando È Buio:** Le superstizioni suggeriscono che iniziare un viaggio quando è buio all'esterno possa portare sfortuna e potenziali pericoli. Il buio è spesso associato all'incertezza e agli ostacoli invisibili. Aspettare che il sole sorga prima di iniziare il viaggio è considerato un modo per allinearsi con energie positive e luce naturale, garantendo un inizio più sicuro e favorevole..",
                image:'/post1photo5.jpg'
            },
            {
                subHeader: "",
                content: "**3. Non guardare indietro dopo aver chiuso la porta di casa:** L'atto di guardare indietro dopo aver lasciato casa è considerato come invitare la sventura, poiché simboleggia esitazione o rimpianto. Questa superstizione suggerisce che guardare indietro potrebbe portare cattiva sorte o causare problemi. Una volta chiusa la porta dietro di te, è meglio andare avanti senza voltarsi per mantenere un'esperienza di viaggio positiva.",
                image:''
            },
            {
                subHeader: "",
                content: "**4. Evita di viaggiare quando la luna è nella sua fase iniziale:** Secondo varie superstizioni, viaggiare durante la fase iniziale della luna (luna nuova) è considerato portatore di sventura o difficoltà. La luna nuova è spesso associata a nuovi inizi e cambiamenti, ma è considerata un momento infausto per iniziare viaggi o fare mosse significative. Per evitare potenziali problemi, è consigliabile pianificare il viaggio durante una fase lunare più favorevole.",
                image:'/post1photo6.jpg'
            },
            {
                subHeader: "",
                content: "**5. Non viaggiare con scarpe nuove:** Indossare scarpe nuove durante il viaggio è considerato sfortunato secondo varie superstizioni. La credenza è che le scarpe nuove possano portare disagio o cattiva sorte durante il viaggio. È meglio indossare scarpe comode, già rodate, per garantire un'esperienza di viaggio più fluida e piacevole.",
                image:''
            },
            {
                subHeader: "Conclusione",
                content: "Sebbene le superstizioni varino ampiamente tra le culture, molte persone trovano conforto nell'aderire a queste credenze durante i loro viaggi. Seguendo queste linee guida, puoi migliorare la tua esperienza di viaggio e potenzialmente evitare trappole comuni associate alla cattiva sorte. Ricorda di goderti il tuo viaggio!",
                image: null
            }
        ]
    },
    {
        id: 2,
        title: 'Genitori di Figli Adulti: Navigare tra Gioie e Sfide',
        photo: '/post2photo1.jpg',
        description: 'Essere genitori di figli adulti può essere una fase della vita sia gratificante che sfidante. Man mano che i tuoi figli diventano adulti, la dinamica della vostra relazione cambia inevitabilmente. Questo articolo esplora come affrontare questo nuovo terreno con grazia e saggezza, fornendo consigli pratici e guida biblica per mantenere relazioni forti e sane con i tuoi figli adulti.',
        sections: [
            {
                subHeader: 'Comprendere il Tuo Nuovo Ruolo',
                content: 'Man mano che i figli diventano adulti, il ruolo dei genitori passa da una supervisione diretta a una posizione più di consulenza e supporto. Comprendere questo nuovo ruolo è cruciale per favorire una relazione sana.',
                image: null,
            },
            {
                subHeader: 'Lasciare Andare',
                content: 'Lasciare andare non significa abbandonare i tuoi figli; significa fidarsi di loro per prendere le proprie decisioni. Questo può essere difficile, specialmente se vedi che stanno facendo scelte con cui non sei d\'accordo. Tuttavia, è importante rispettare la loro indipendenza e permettere loro di imparare dalle loro esperienze.',
                image: null,
            },
            {
                subHeader: 'Fornire Supporto',
                content: 'Sebbene il tuo ruolo cambi, il tuo supporto rimane vitale. Offri consigli quando richiesto, fornisci supporto emotivo nei momenti difficili e celebra i loro successi. Sii una presenza costante nella loro vita, mostrando amore e accettazione incondizionati.',
                image: null,
            },
            {
                subHeader: 'Comunicazione Efficace',
                content: 'La comunicazione è fondamentale per mantenere una relazione forte con i tuoi figli adulti. Adattare il tuo stile di comunicazione al loro nuovo status di adulti può aiutare a prevenire incomprensioni e a favorire il rispetto reciproco.',
                image: '/post2photo2.jpg',
            },
            {
                subHeader: 'Ascolto Attivo',
                content: 'Pratica l\'ascolto attivo dando la tua completa attenzione, riconoscendo i loro sentimenti e astenendoti dal proporre immediatamente soluzioni. Questo mostra rispetto per le loro opinioni e aiuta a costruire fiducia.',
                image: null,
            },
            {
                subHeader: 'Rispettare i Confini',
                content: 'Riconosci e rispetta i confini stabiliti dai tuoi figli adulti. Evita consigli o critiche non richieste e sii consapevole del loro bisogno di privacy e autonomia.',
                image: null,
            },
            {
                subHeader: 'Supportare Senza Controllare',
                content: 'Trovare l\'equilibrio tra sostenere i tuoi figli adulti e non controllarli può essere complicato. Ecco alcuni suggerimenti per mantenere questo equilibrio.',
                image: null,
            },
            {
                subHeader: 'Offrire Guida',
                content: 'Invece di dettare cosa dovrebbero fare, offri guida condividendo le tue esperienze e suggerendo possibili soluzioni. Incoraggiali a prendere le proprie decisioni e rispetta le loro scelte.',
                image: null,
            },
            {
                subHeader: 'Incoraggiare l\'Indipendenza',
                content: 'Incoraggia i tuoi figli a essere autosufficienti. Questo potrebbe significare fare un passo indietro e permettere loro di affrontare i propri problemi, anche se ciò comporta errori. Queste esperienze sono lezioni preziose di crescita personale e responsabilità.',
                image: null,
            },
            {
                subHeader: 'Affrontare i Conflitti',
                content: 'I conflitti sono naturali in ogni relazione, ma possono essere particolarmente difficili con i figli adulti. Affrontare i conflitti in modo costruttivo può rafforzare il vostro legame.',
                image: '/post2photo3.jpg',
            },
            {
                subHeader: 'Scegliere le Battaglie con Saggezza',
                content: 'Non tutte le divergenze devono essere affrontate. Concentrati sui problemi che contano di più e lascia perdere quelli minori. Questo approccio aiuta a mantenere l\'armonia e il rispetto reciproco.',
                image: null,
            },
            {
                subHeader: 'Praticare il Perdono',
                content: 'Trattenere rancori o errori passati può danneggiare la tua relazione. Pratica il perdono e incoraggia una comunicazione aperta e onesta per risolvere i conflitti e andare avanti.',
                image: null,
            },
            {
                subHeader: 'Mantenere la Propria Vita',
                content: 'Man mano che i tuoi figli diventano indipendenti, è essenziale concentrarsi sulla propria vita e sul proprio benessere. Questo non solo beneficia te, ma costituisce anche un esempio positivo per i tuoi figli.',
                image: null,
            },
            {
                subHeader: 'Coltivare Interessi',
                content: 'Usa questo tempo per coltivare interessi e hobby che potresti aver messo da parte mentre cresci i tuoi figli. Impegnarsi in attività appaganti può portare gioia e un senso di realizzazione.',
                image: null,
            },
            {
                subHeader: 'Rafforzare Altre Relazioni',
                content: 'Investi tempo nel rafforzare la tua relazione con il coniuge, gli amici e la comunità. Una rete di supporto forte è essenziale per il benessere emotivo.',
                image: null,
            },
            {
                subHeader: 'Guida Biblica per Essere Genitori di Figli Adulti',
                content: 'La Bibbia offre una saggezza senza tempo che può guidare la tua relazione con i tuoi figli adulti.',
                image: null,
            },
            {
                subHeader: 'Fidarsi del Piano di Dio',
                content: 'Proverbi 3:5-6 ci incoraggiano a fidarci del Signore con tutto il nostro cuore e a non appoggiarci al nostro proprio intendimento. Fidati che Dio ha un piano per i tuoi figli e che li guiderà nel loro cammino.',
                image: null,
            },
            {
                subHeader: 'Praticare Amore e Pazienza',
                content: '1 Corinzi 13:4-7 ci ricorda che l\'amore è paziente e gentile. Avvicinati ai tuoi figli adulti con pazienza, gentilezza e un cuore amorevole, anche nei momenti difficili.',
                image: null,
            },
            {
                subHeader: 'Conclusione',
                content: 'Essere genitori di figli adulti è un viaggio unico e in evoluzione. Comprendendo il tuo nuovo ruolo, praticando una comunicazione efficace, supportando senza controllare, affrontando i conflitti in modo costruttivo e mantenendo la tua vita, puoi favorire una relazione amorevole e rispettosa con i tuoi figli adulti. Ricorda di cercare la saggezza e la guida di Dio attraverso la preghiera e le scritture mentre affronti questa fase gratificante della genitorialità.',
                image: null,
            }
        ]
      },
      {
        id: 3,
        title: 'Approfondire la Tua Vita di Preghiera: Un Viaggio di Fede e Connessione',
        photo: '/post3photo1.jpg',
        description: 'Esplorare come approfondire la tua vita di preghiera può arricchire il tuo cammino spirituale e avvicinarti a Dio. Questo articolo fornisce passi pratici e approfondimenti biblici per arricchire la tua vita di preghiera.',
        sections: [
            {
                subHeader: 'Comprendere l\'Importanza della Preghiera',
                content: 'La preghiera è un aspetto fondamentale della fede cristiana, servendo come mezzo per comunicare con Dio, cercare la Sua guida ed esprimere la nostra gratitudine e i nostri bisogni. Comprendere l\'importanza della preghiera è il primo passo per approfondire la tua vita di preghiera. La Bibbia ci insegna che la preghiera è uno strumento potente. In Filippesi 4:6-7, siamo incoraggiati a presentare le nostre richieste a Dio tramite preghiere e suppliche con ringraziamento, e in cambio riceveremo la pace di Dio che supera ogni comprensione. Riconoscere la preghiera come parte vitale della nostra vita spirituale ci aiuta a darle la priorità e a cercare di approfondire la nostra connessione con Dio attraverso di essa.',
                image: null,
            },
            {
                subHeader: 'Creare una Routine di Preghiera Consistente',
                content: 'Sviluppare una routine di preghiera consistente è essenziale per approfondire la tua vita di preghiera. Riservare del tempo dedicato ogni giorno alla preghiera aiuta a stabilire un\'abitudine che diventa una parte naturale della tua vita quotidiana. Scegli un momento che funzioni meglio per te, che sia presto al mattino, durante la pausa pranzo o prima di andare a letto. La coerenza è fondamentale. Marco 1:35 illustra l\'importanza di una routine descrivendo come Gesù si alzasse presto al mattino per pregare in un luogo solitario. Questa pratica non solo rafforza la tua relazione con Dio, ma fornisce anche un senso di pace e chiarezza che può guidarti durante tutta la giornata.',
                image: '/post3photo2.jpg',
            },
            {
                subHeader: 'Incorporare Diversi Tipi di Preghiera',
                content: 'Incorporare vari tipi di preghiera può arricchire la tua vita di preghiera e mantenerla dinamica. Ci sono diverse forme di preghiera, tra cui adorazione, confessione, ringraziamento e supplica. L\'adorazione implica lodare Dio per la Sua grandezza e riconoscere la Sua sovranità. La confessione ci permette di cercare il perdono per i nostri peccati e mancanze, purificando i nostri cuori e le nostre menti. Il ringraziamento è esprimere gratitudine per le benedizioni di Dio, favorendo una mentalità positiva e riconoscente. La supplica è quando presentiamo le nostre richieste e bisogni a Dio, confidando nella Sua provvidenza e nei Suoi tempi. Integrando questi diversi tipi di preghiera, possiamo sperimentare una vita di preghiera più equilibrata e appagante.',
                image: null,
            },
            {
                subHeader: 'Ascoltare nella Preghiera',
                content: 'Spesso ci concentriamo sul parlare a Dio durante la preghiera, ma ascoltare è altrettanto importante. La preghiera è una conversazione a due vie. Prendendo tempo per stare in silenzio e ascoltare, ci apriamo a sentire la voce di Dio e ricevere la Sua guida. Il Salmo 46:10 ci incoraggia a "Fermatevi e sappiate che io sono Dio". Nel silenzio, possiamo percepire la presenza di Dio e discernere la Sua volontà per le nostre vite. Pratica l\'essere tranquillo e attento durante il tuo tempo di preghiera, permettendo a Dio di parlare al tuo cuore. Questa pratica può approfondire la tua connessione con Lui e fornire chiarezza e direzione.',
                image: null,
            },
            {
                subHeader: 'Confidare nei Tempi di Dio',
                content: 'Confidare nei tempi di Dio è un aspetto essenziale di una vita di preghiera profonda. Spesso, le nostre preghiere potrebbero non essere esaudite nel modo o nel tempo che ci aspettiamo. Confidare che i piani di Dio sono perfetti e che i Suoi tempi sono impeccabili è cruciale. Isaia 55:8-9 ci ricorda che le vie e i pensieri di Dio sono più alti dei nostri. Quando confidiamo nei Suoi tempi, possiamo trovare pace e certezza, sapendo che Lui è al controllo. Questa fiducia ci permette di rimanere pazienti e fedeli, anche quando le risposte sono ritardate o diverse da ciò che avevamo previsto.',
                image: '/post3photo3.jpg',
            },
            {
                subHeader: 'Conclusione',
                content: 'Approfondire la tua vita di preghiera è un viaggio che richiede impegno, coerenza e la volontà di avvicinarti a Dio. Comprendendo l\'importanza della preghiera, creando una routine consistente, incorporando diversi tipi di preghiera, ascoltando Dio e confidando nei Suoi tempi, puoi arricchire la tua vita spirituale e rafforzare la tua relazione con Lui. Ricorda, la preghiera non riguarda solo la presentazione delle nostre richieste a Dio, ma anche la costruzione di una connessione più profonda e intima con il nostro Creatore. Cerca la Sua presenza quotidianamente e permetti alla tua vita di preghiera di trasformare il tuo cammino di fede.',
                image: null,
            }
        ],
    }
  ]



const Post = () => {
    const { id } = useParams();
    const post = posts.find(post => post.id === parseInt(id));

    const postBackgroundStyle = {
      color: '#1a237e',
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
}, []);

    return (
      <Container style={postBackgroundStyle}>
       <FlexboxGrid justify="center">
                    <FlexboxGrid.Item>
                    <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                        <ArrowLeftOutlined style={{ marginRight: 8 }} />
                        <span>Inizio</span>
                    </Link>
                    </FlexboxGrid.Item>
        </FlexboxGrid>
            <FlexboxGrid justify="center" align="middle" style={{ height: '100%', padding: '20px 0' }}>
                <FlexboxGrid.Item colspan={24} style={{ textAlign: 'center' }}>
                    <Header>
                        <h2>{post.title}</h2>
                    </Header>
                    <img src={post.photo} style={{ maxWidth: '100%' }} />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <Content>
                {post.sections.map((section, index) => (
                    <Panel key={index} bordered style={{ marginBottom: 20, marginLeft: 20, marginRight: 20 }}>
                        <h3>{section.subHeader}</h3>
                        {section.image && (
                            <FlexboxGrid justify="center">
                                <FlexboxGrid.Item>
                                    <img src={section.image} alt={section.subHeader} style={{ maxWidth: '100%' }} />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        )}
                        <p>{formatContent(section.content)}</p>
                        
                        <Divider />
                    </Panel>
                ))}
            </Content>
        </Container>
    );
};

function formatContent(content) {
    // Split the content into parts: handling both bold and list items
    const parts = content.split(/(\*\*.*?\*\*|\* .+)/);

    return parts.map((part, index) => {
        // Check for bold text
        if (part.startsWith('**') && part.endsWith('**')) {
            return (
                <React.Fragment key={index}>
                    <br />
                    <br />
                    <strong>{part.slice(2, -2)}</strong>
                    <br />
                    <br />
                </React.Fragment>
            );
        }
        // Render regular text
        return <span key={index}>{part}</span>;
    });
}

export default Post;