import React from 'react';

import PostCard from './PostCard';

const posts = [
  {
    id: 3,
    photo: 'post3photo0.jpg',
    title: 'Approfondire la Tua Vita di Preghiera: Un Viaggio di Fede e Connessione',
    summary: ' Approfondire la tua vita di preghiera arricchisce il tuo cammino spirituale e ti avvicina a Dio.',
    views: '58,532 visualizzazioni',
    authore: 'Scritto da: Cardinal Angelo Scola'
 },
 {
  id: 1,
  photo: 'post1photo1.jpg',
  title: '5 Cose da Fare e 5 Cose da Evitare Quando si Viaggia a Luglio e Agosto',
  summary: 'Scopri le migliori pratiche per viaggiare in estate con la nostra guida su cosa fare e cosa evitare a luglio e agosto.',
  views: '353,503 visualizzazioni',
  authore: 'Scritto da: Cardinal Angelo Scola'
},
    {
        id: 2,
        photo: 'post2photo0.jpg',
        title: 'Navigare la Genitorialità con Figli Adulti: Bilanciare Supporto e Indipendenza',
        summary: 'Man mano che i tuoi figli diventano adulti, il tuo ruolo cambia da supervisione diretta a supporto e guida. Questo post offre consigli su come mantenere una relazione sana, rispettare i confini e trovare equilibrio, mentre abbracci anche i tuoi interessi personali.',
        views: '190,532 visualizzazioni',
        authore: 'Scritto da: Cardinal Angelo Scola'
     },
     
    
  ];

const PostList = () => {
    return (
        <div className="post-list">
         {posts.map(post => (
         <PostCard key={post.id} post={post} />
      ))}
    </div>
    );
};

export default PostList;