import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';

const { Meta } = Card;

const PostCard = ({ post }) => {
    const imagePath = process.env.PUBLIC_URL + `/${post.photo}`;

    return (
        <Link to={`/post/${post.id}`} className="post-link" style={{ textDecoration: 'none', color: 'black' }}>
            <Card
            hoverable
            style={{ width: 300, margin: '0 auto', marginBottom: 20}}
            cover={<img alt="example" src={imagePath} />}
             >
                <Meta
                title={<div style={{ textAlign: 'center',  whiteSpace: 'normal', wordWrap: 'break-word'}}>{post.title}</div>}
                 description={
                <div style={{ 
                    color: 'black', 
                    textDecoration: 'none' 
                }}>
                    {post.summary}
                </div>
            }
                />
                <div className="post-details" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                <span>{post.views}</span>
                <span>{post.authore}</span>
                </div>
            </Card>
        </Link>
    );
  };
  
  export default PostCard;