import React from 'react';
import Post from '../components/Post';

const PostPage = () => {
    return (
        <div>
            <Post />
        </div>
    );
};

export default PostPage;