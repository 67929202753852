import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import PostPage from './pages/PostPage';
import NotifyIfGermanVisitor from './components/NotifyIfGermanVisitor';

const App = () => {
    return (
        <Router>
            <div>
            <NotifyIfGermanVisitor />
                <main>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/post/:id" element={<PostPage />} />
                    </Routes>
                </main>
            </div>
        </Router>
        
    );
};

export default App;